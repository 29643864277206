<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-2">mdi-currency-inr</v-icon>Daywise Sale Report
        </h2>
    </v-flex>
    <v-container>
        <v-flex>
            <v-layout align-center>
                <v-flex text-right>
                    <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex>
            <v-container>
                <v-layout v-if="items.length>0" column>
                    <v-flex text-center>
                        <h2 class="report-heading">Daywise Sale Report</h2>
                    </v-flex>
                     <v-flex text-right>
                        <export-to-csv :data="items" :fileName="`daywise-sales-report`"></export-to-csv>
                        <export-to-xls :data="items" :fileName="`daywise-sales-report`"></export-to-xls>
                    </v-flex>
                    <v-simple-table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th class="text-right">Total Bills</th>
                            <th class="text-right">Sale</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.code">
                            <td>{{item._id}}</td>
                            <td class="text-right">{{item.bills}}</td>
                            <td class="text-right"><v-icon small>mdi-currency-inr</v-icon> {{item.sale}}/-</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-right border-top big-heading">Total</td>
                            <td class="text-right border-top big-heading"><v-icon>mdi-currency-inr</v-icon> {{totalSale}}/-</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        
                    </tfoot>
                </v-simple-table>
                </v-layout>
                <v-flex text-center>
                    <progress-bar :show="loading"></progress-bar>
                </v-flex>
            </v-container>
        </v-flex>
    </v-container>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            items: [],
            loading: false,
            downloadType: 'csv',
            totalSale: 0
        }
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                this.items = await this.getItem(appConstants.DAYWISE_SALE_REPORT)
                this.totalSale = 0
                this.items.forEach(rec =>{
                    this.totalSale+=rec.sale
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style scoped>
.report-heading{
    font-size: 1.2rem !important;
    font-weight: 500
}
.border-top{
    border-top: solid 1px black;
}
.big-heading{
    font-size: 1.3rem;
    font-weight: 500;
}
</style>
